
.contactHero {
    margin-top: 80px;
    position: relative;
    text-align: center;
    color: white;
    background-image: url('../imgs/pattern2.jpg');
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contactHeroText {
    padding: 20px;
    border-radius: 10px;
  }
  
  .contactHeroText h1 {
    font-size: 48px;
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  .contactHeroText p {
    font-size: 18px;
    margin-bottom: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  
  /* Additional styles for the contact page */
  .contactPage {
    font-family: Arial, sans-serif;
    color: #333;
  }


  .mapAndDetailsSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 40px 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .contactDetails {
    flex: 1;
    min-width: 300px;
    padding-right: 20px;
  }
  
  .contactDetails h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .contactDetails p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .mapContainer {
    flex: 2;
    min-width: 300px;
    border-radius: 10px;
  }
  
  .mapContainer iframe {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Ensure the element is initially hidden */
.fade-in {
    opacity: 0;
    transform: translateY(20px); /* Optional: adds a subtle slide effect */
  }
  
  /* Define the fade-in animation */
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Apply the animation when the element is visible */
  .fade-in.visible {
    animation: fadeIn 1s ease-in forwards;
  }