/* General styles for the services page */
.servicesPage {
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  /* Hero section styles */
  .servicesHero {
    margin-top: 80px;
    position: relative;
    text-align: center;
    color: white;
    background-image: url('../imgs/pattern.jpg');
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .servicesHeroText {
    padding: 20px;
    border-radius: 10px;
  }
  
  .servicesHeroText h1 {
    font-size: 48px;
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  .servicesHeroText p {
    font-size: 18px;
    margin-bottom: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  /* Services section styles */
  .sSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .serviceP {
    width: 250px;
    margin: 20px;
    text-align: center;
  }
  
  .serviceP h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .serviceP p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .sImage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .sImage:hover {
    transform: scale(1.05);
  }
  
  .servicePrice {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    color: #333;
  }

  /* Contact note at the bottom */
.contactNote {
    margin-top: -40px;
    text-align: center;
    padding: 20px;
    padding-bottom: 40px;
    background-color: #f9f9f9;
    font-size: 32px;
    font-weight: bold;
  }
  
  /* Ensure the element is initially hidden */
.fade-in {
    opacity: 0;
    transform: translateY(20px); /* Optional: adds a subtle slide effect */
  }
  
  /* Define the fade-in animation */
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Apply the animation when the element is visible */
  .fade-in.visible {
    animation: fadeIn 1s ease-in forwards;
  }
