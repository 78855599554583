.homepagePhoto {
    transition: opacity 1.5s ease-in-out;
    opacity: 1;
    object-fit: cover; 
    width: 100%;
    height: 800px;
    margin-top: 80px;
}

.fade {
    opacity: 0;
}

.homePage .container {
    height: 200px;
    width: auto;
    background-color: white;
}

/* Hero Section */
.hero {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .heroText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  .heroText h1, .heroText p {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}
  
  /* About Section */
.aboutSection {
    background-color: #f9f9f9;
    padding: 60px 0;
    width: 100%;
}

.aboutContent {
    max-width: 800px;
    margin: 0 auto; 
    padding: 0 20px;
    text-align: center;
}

.aboutContent h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.aboutContent p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.6;
}

.meetTeamButton {
    background-color: transparent;
    color: black;
    padding: 15px 30px;
    border: 2px solid black;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.meetTeamButton:hover {
    background-color: black;
    color: white;
}
  
  /* Services Overview */
  .servicesSection {
    padding: 50px;
    background-color: #ffffff;
    text-align: center;
  }
  
  .serviceCategories {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .service {
    width: 200px;
    margin: 20px;
  }
  .servicesSection h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .service h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .serviceImage {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px; 
    border: 2px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.serviceImage:hover {
    transform: scale(1.05); 
}
  
.testimonialsSection {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
}
.testimonialsSection h2 {
    font-size: 36px;
    margin-bottom: 20px;
}


.testimonials {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

blockquote {
  font-style: italic;
  color: #333;
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  border-left: 5px solid #000000;
  border-radius: 5px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  position: relative;
}

blockquote footer {
  font-weight: bold;
  color: #666;
  margin-top: 10px;
  display: block;
}

blockquote::before {
  content: open-quote;
  font-size: 30px;
  color: #333;
  position: absolute;
  left: -4px;
  top: -10px;
}

blockquote::after {
  content: close-quote;
  font-size: 30px;
  color: #333;
  position: absolute;
  right: -4px;
  bottom: -10px;
}

/* Feedback Form */

.feedbackForm {
  margin-top: 20px;
  text-align: center;
}

.expandButton {
  background-color: transparent;
  color: black;
  padding: 15px 30px;
  border: 2px solid black;
  cursor: pointer;
  font-size: 18px;
  margin-top: -15px;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.expandButton:hover {
  background-color: black;
  color: white;
}

form {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

form div {
  margin-bottom: 10px;
}

form label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

form input[type="text"],
form textarea,
form input[type="file"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
}

form textarea {
  height: 100px;
  resize: vertical;
}

form button[type="submit"] {
  background-color: transparent;
  color: black;
  padding: 10px 20px;
  border: 1px solid black;
  cursor: pointer;
  font-size: 16px;
  border-radius: 3px;
  margin-right: 10px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

form button[type="submit"]:hover {
  background-color: black;
  color: white;
}

.cancelButton {
  background-color: transparent;
  color: black;
  padding: 10px 20px;
  border: 1px solid black;
  cursor: pointer;
  font-size: 16px;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cancelButton:hover {
  background-color: #ccc;
}

.feedbackForm p {
  margin-top: 15px;
  color: green;
  font-size: 5px;
  text-align: center;
}
  
  /* Footer */
  .footer {
    padding: 30px;
    background-color: black;
    color: white;
  }
  
  .footerContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .contactInfo, .socialLinks {
    width: 45%;
    margin-bottom: 20px;
  }
  
  .footerNote {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
  }

  /* Define the fade-in animation */
.fade-in {
  opacity: 0;
  transform: translateY(20px); /* Optional: adds a subtle slide effect */
}

/* Define the fade-in animation */
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation when the element is visible */
.fade-in.visible {
  animation: fadeIn 1s ease-in forwards;
}
  